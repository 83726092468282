import React from "react";

export default function NotFound() {
  return (
    <div>
      <h1>404</h1>
      <h3>This is not the page you are looking for</h3>
    </div>
  );
}
